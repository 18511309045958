<template>
<div>
  <div class="banner">
    <div class="header">
      <div class="container">
        <nav class="navbar navbar-expand-lg pt-3 pb-3">
          <div class="container-fluid">
            <a class="navbar-brand logo" href="#/home">
              <img :src="logo" alt="logo">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon "></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a :class="{'nav-link':true,'active':$route.path == '/home'}" aria-current="page" href="#/home">首页</a>
                </li>
                <li class="nav-item">
                  <a :class="{'nav-link':true,'active':$route.path == '/product'}" href="#/product">产品</a>
                </li>
                <li class="nav-item">
                  <a :class="{'nav-link':true,'active':$route.path == '/case' || $route.path == '/caseDetails'}" href="#/case">客户案例</a>
                </li>
                <li class="nav-item">
                  <a :class="{'nav-link':true,'active':$route.path == '/trends'}" href="#/trends">近期动态</a>
                </li>
                <li class="nav-item">
                  <a :class="{'nav-link':true,'active':$route.path == '/support'}" href="#/support">合作支持</a>
                </li>
                <li class="nav-item">
                  <a :class="{'nav-link':true,'active':$route.path == '/about'}" href="#/about">关于我们</a>
                </li>
                <li class="nav-item">
                  <a :class="{'nav-link':true,'active':$route.path == '/contact'}" href="#/contact">联系我们</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="http://ssb.bak365.cn/WebApp/#/login?type=customer" target="_blank">服务优选</a>
                </li>
              </ul>

            </div>
          </div>
        </nav>
      </div>
    </div>

    <div class="home_banner" v-if="$route.path == '/home'">
      <video :src="indexhb" autoplay loop muted></video>
      <div class="home_banner_mark">
        <div class="title" data-aos="fade-up">烘焙行业开店一站式解决方案</div>
        <div class="sm_title" data-aos="fade-up">数智化赋能的智能运营平台</div>
        <div class="apply" @click="$router.push({path: '/about'})">申请试用</div>
      </div>
    </div>
    <banners :backgroundImage="banner1" :title="'全渠道的线上线下融合才能发挥烘焙企业的最大优势'" v-if="$route.path == '/product'"></banners>
    <banners :backgroundImage="banner2" :title="'请相信，好的作品会说话'" v-if="$route.path == '/case'"></banners>
    <banners :backgroundImage="banner2" :title="'客户案例'" v-if="$route.path == '/caseDetails'"></banners>
    <banners :backgroundImage="banner3" :title="'最新功能内容及公司合作相关动态'" v-if="$route.path == '/trends'"></banners>
    <banners :backgroundImage="banner4" :title="'17年技术沉淀，成就行业典范'" v-if="$route.path == '/support'"></banners>
    <banners :backgroundImage="banner5" :title="'一家以技术和服务为核心的软件公司'" v-if="$route.path == '/about'"></banners>
    <banners :backgroundImage="banner6" :title="'有什么需要咨询的，可随时联系我们！'" v-if="$route.path == '/contact'"></banners>
  </div>
<!--  {{$route.path}}-->
  <router-view/>
  <div class="getQuote pt-5 pb-5">
    <div class="container">
      <h4>我们已经准备好了,你呢？</h4>
      <div class="quote_btn_line">
        <div class="quote_line"></div>
        <a href="http://wpa.qq.com/msgrd?v=3&uin=317042588&site=qq&menu=yes" target="_blank" class="quote_btn">获取软件报价</a>

        <div class="quote_line"></div>
      </div>
      <p>饼店理轻松掌控，为您的企业数字化保驾护航！</p>
    </div>
  </div>
  <footer class="pt-5 pb-2">
    <div class="container">
      <div class="footer_row">
        <div class="footer_row_list1">
          <div class="footer_title">产品</div>
          <div class="footer_text_list">
            <div class="footer_text_link" v-for="(item) in DataList" :key="item.SID" @click="seeDetails(item.SID)">{{item.Title}}</div>
          </div>
        </div>
        <div class="footer_row_list2">
          <div class="footer_title">客户案例</div>
        </div>
        <div class="footer_row_list3">
          <div class="footer_title">近期动态</div>
        </div>
        <div class="footer_row_list4">
          <div class="footer_title">合作支持</div>
        </div>
        <div class="footer_row_list5">
          <div class="footer_title">全国免费咨询</div>
          <div class="tel">4006-591-365</div>
          <p class="footer_text_text">业务咨询：4006-591-365 / 027-85750188</p>
          <p class="footer_text_text">节假值班：027-85750188</p>
          <p class="footer_text_text">联系地址：湖北省武汉市洪山区光谷大道光谷企业天地4号楼16楼</p>
        </div>
      </div>
    </div>
    <div class="line mt-5 mb-4"></div>
    <div class="container" style="color: #959595">
      <p class="beian">武汉烘焙三六五科技有限公司 备案号：<a href="https://beian.miit.gov.cn/">鄂ICP备05004217号-4</a></p>
    </div>
  </footer>
  <a href="http://kf.bak365.cn/index/index/home?visiter_id=&visiter_name=&avatar=&business_id=38&groupid=0&special=38" target="_blank" class="cgwl-form" id="cgwl-kefu">
    <i class="cgwl-icon"></i>
    <form class="cgwl-item" action="http://kf.bak365.cn/index/index/home?visiter_id=&visiter_name=&avatar=&business_id=38&groupid=0" method="post" target="_blank" >
      <input type="hidden" name="product" value=''>
      <!-- <input type="submit" value='在线咨询'> -->
      <span style="color:#fff;">在线咨询</span>
    </form>
  </a>
</div>
</template>
<script>
// console.log(AOS);
</script>
<script>
import indexhb from '@/static/indexhb.mp4'
import logo from '@/static/open_03.png'
import banner1 from '@/static/moban.jpg'
import banner2 from '@/static/case.jpg'
import banner3 from '@/static/newbanner.jpg'
import banner4 from '@/static/seo.jpg'
import banner5 from '@/static/about.png'
import banner6 from '@/static/contact.jpg'
import banners from "@/layout/components/banner";
export default {
  name: "Layout",
  components:{
    banners
  },
  data(){
    return {
      indexhb: indexhb,
      logo:logo,
      banner1:banner1,
      banner2:banner2,
      banner3:banner3,
      banner4:banner4,
      banner5:banner5,
      banner6:banner6,
      DataList: []
    }
  },
  created() {
    this.getList()
  },
  methods:{
    async getList() {
      try {
        let {Data} = await this.$getList({
          Handler: 'List',
          MenuNo: '02',
          PageSize: 10,
          PageNum: 1,
        }, 'Gate', 'Info')
        this.DataList = Data
        console.log(this.DataList);
      } catch (e) {

      }
    },
    // 查看详情
    seeDetails(SID){
      this.$router.push({
        path: '/productDetails',
        query:{
          SID:SID
        }
      })
    }
  }
}
</script>

<style scoped>
.banner{
  width: 100%;
  position: relative;
}
.home_banner{
  width: 100%;
  position: relative;
}
video{
  width: 100%;
  display: block;
}
.header{
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}
.home_banner_mark{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.5);
}
.nav-link{
  color: white;
}
.nav-link:hover{
  color: #ff6727;
}
.logo{
  display: block;
  width: 150px;
}
img{
  width: 100%;
  display: block;
}
.title{
  font-size: 3.5rem;
  position: absolute;
  left: 20%;
  top: 30%;
  color: white;
  font-weight: bold;
}
.sm_title{
  font-size: 2rem;
  position: absolute;
  right: 20%;
  top: 45%;
  color: white;
}
.apply{
  width: 200px;
  height: 45px;
  border: 1px solid white;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 60%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  transition: all 0.3s;
  border-radius: 100px;
}
.apply:hover{
  background: #ff6727;
  border: 1px solid #ff6727;
}
footer{
  background: #1e1d1d;
}
.footer_row{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5,1fr);
  grid-template-rows: auto;
  word-wrap: break-word; /* 允许在长单词内部换行 */
  word-break: break-all; /* 允许在任意位置换行 */
}
.footer_title{
  font-size: 16px;
  color: #eae8e8;
  margin-bottom: 20px;
}
.footer_text_link{
  display: block;
  line-height: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #959595;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.5s;
  word-wrap: break-word; /* 允许在长单词内部换行 */
  word-break: break-all; /* 允许在任意位置换行 */
  cursor: pointer;
}
.footer_text_link:hover{
  transform: translateX(10px);
}
.footer_text_text{
  width: 100%;
  display: block;
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  color: #959595;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.5s;
  word-wrap: break-word; /* 允许在长单词内部换行 */
  word-break: break-all; /* 允许在任意位置换行 */
  white-space: pre-wrap;
}
.tel{
  font-size: 40px;
  color: white;
  font-family: Impact;
}
.beian{
  color: #6a6a6a;
  font-size: 14px;
}
.beian>a{
  color: #959595;
  text-decoration: none;
}
.line{
  width: 100%;
  height: 1px;
  background: #363636;
}
.getQuote{
  width: 100%;
  background: white;
  text-align: center;
}
.quote_btn_line{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}
.quote_btn{
  width: 350px;
  display: block;
  text-decoration: none;
  background: #ff6727;
  font-size: 22px;
  color: white;
  line-height: 55px;
  margin: 0 25px;
}
.quote_line{
  width: 250px;
  height: 1px;
  background: #B5B5B5;
}
.getQuote p{
  letter-spacing:5px;
}

.cgwl-form {
  filter: alpha(opacity = 0);
  transition: 0.2s ease-out;
  position: fixed;
  top: 60%;
  width: 70px;
  right: 0;
  font-size: 12px;
  background-color: #488bff;
  z-index: 9999;
  padding-bottom: 10px;
}
.cgwl-form i { cursor:pointer;}

.cgwl-form .cgwl-item {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  z-index: 9999;
  margin:0;
}

.cgwl-item:hover{
  color: red;
}

.cgwl-form input{
  cursor:pointer;border:none;background:transparent;color:#fff;margin: 3px 0;
}

.cgwl-form input:hover{
  color: #043b9a
}

.cgwl-icon{
  display: block;
  width: 31px;
  height: 30px;
  background: url("@/assets/chat.png");
  margin: 20px auto;
  margin-bottom: 4px;
}

@media screen and (max-width: 768px){
  .getQuote{
    display: none;
  }
  .logo{
    width: 80px;
  }
  .navbar-toggler{
    color: white !important;
  }
  .navbar-toggler>span{
    color: white;
  }
  .title{
    font-size: 1.2rem;
    font-weight: 400;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .sm_title{
    font-size: 1rem;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .apply{
    height: 30px;
    font-size: 12px;
    top: 70%;
    width: 180px;
  }
  .footer_row{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-template-rows: auto;
  }
  .footer_row_list1,.footer_row_list2,.footer_row_list3,.footer_row_list4{
    display: none;

  }
}
</style>
