import axios from 'axios'

// create an axios instance
const service = axios.create({
    baseURL: location.origin + '/' + 'Service.ashx', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        // let url = config.url
        // config.url = url
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.State !== 'OK') {
            // Message({
            //   message: res.Msg || 'Error',
            //   type: 'error',
            //   duration: 5 * 1000
            // })
            alert(res.Msg || 'Error')

            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
                // to re-login
                // MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
                //   confirmButtonText: 'Re-Login',
                //   cancelButtonText: 'Cancel',
                //   type: 'warning'
                // }).then(() => {
                //   store.dispatch('user/resetToken').then(() => {
                //     location.reload()
                //   })
                // })
            }
            return Promise.reject(new Error(res.Msg || 'Error'))
        } else {
            return res.Data
        }
    },
    error => {
        console.log('err' + error) // for debug
        // Message({
        //   message: error.message,
        //   type: 'error',
        //   duration: 5 * 1000
        // })
        alert(error.message)
        return Promise.reject(error)
    }
)

export default service
