import Vue from 'vue'
import App from './App.vue'
import router from "@/router";

Vue.config.productionTip = false
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
import '@/style/style.css'
import 'animate.css'
import 'aos/dist/aos.css'
import AOS from 'aos/dist/aos'
AOS.init()

import {getList} from "@/api/user";
Vue.prototype.$getList = getList

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
