<template>
<div>
  <div class="product_banner" :style="{backgroundImage:'url('+backgroundImage+')'}">
    <div class="banner_mark">
      <div class="banner_title animate__animated animate__bounce">{{title}}</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "banner",
  props:{
    backgroundImage: '',
    title: ''
  }
}
</script>

<style scoped>
.product_banner{
  width: 100%;
  position: relative;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.banner_mark{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.5);
}
.banner_title{
  font-size: 2.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  color: white;
}
@media screen and (max-width: 768px){
  .product_banner{
    height: 200px;
  }
  .banner_title{
    font-size: 1rem;
  }
}
</style>
