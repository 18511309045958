import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter)
import layout from '@/layout'



let constantRoutes = [
    {
        path: '/',
        component: layout,
        redirect: '/home',
        children:[
            {
                path: 'home',
                name: 'home',
                component: () => import('@/view/home'),
                meta: {title: '首页', icon: 'el-icon-s-help'}
            },
            {
                path: 'product',
                name: 'product',
                component: () => import('@/view/product'),
                meta: {title: '产品', icon: 'el-icon-s-help'}
            },
            {
                path: 'case',
                name: 'case',
                component: () => import('@/view/case'),
                meta: {title: '客户案例', icon: 'el-icon-s-help'}
            },
            {
                path: 'trends',
                name: 'trends',
                component: () => import('@/view/trends'),
                meta: {title: '近期动态', icon: 'el-icon-s-help'}
            },
            {
                path: 'support',
                name: 'support',
                component: () => import('@/view/support'),
                meta: {title: '合作支持', icon: 'el-icon-s-help'}
            },
            {
                path: 'about',
                name: 'about',
                component: () => import('@/view/about'),
                meta: {title: '关于我们', icon: 'el-icon-s-help'}
            },
            {
                path: 'contact',
                name: 'contact',
                component: () => import('@/view/contact'),
                meta: {title: '联系我们', icon: 'el-icon-s-help'}
            },
            {
                path: 'trendsDetails',
                name: 'trendsDetails',
                component: () => import('@/view/detail/trendsDetails'),
                meta: {title: '近期动态', icon: 'el-icon-s-help'}
            },
            {
                path: 'caseDetails',
                name: 'caseDetails',
                component: () => import('@/view/detail/caseDetails'),
                meta: {title: '客户案例', icon: 'el-icon-s-help'}
            },
            {
                path: 'productDetails',
                name: 'productDetails',
                component: () => import('@/view/detail/productDetails'),
                meta: {title: '产品详情', icon: 'el-icon-s-help'}
            },
        ],
    },
    {
        path: '/app',
        component: () => import('@/view/app'),
        name: 'home',
        meta: {title: '首页', icon: 'el-icon-s-help'}
    }
]
const createRouter = () => new VueRouter({
    // mode: 'history', // require service support
    mode: 'hash',
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes

})

const router = createRouter()
router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = 'bak365'+to.meta.title;
    }
    next();
});
export default router
